
/* App.css */
/* App.css */

/* Existing styles... */

.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.text {
  line-height: 2;
}

.mt-4 {
  margin-top: 16px;
}


body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #F5F5F7;
  color: #333;
}

a {
  text-decoration: none;
}

nav {
  background-color: #F5F5F7;
  border-bottom: 1px solid #ddd;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

nav li {
  margin: 0 15px;
}

nav a {
  color: #333;
  font-weight: bold;
}

nav a:hover {
  color: #555;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.text {
  line-height: 1.6;
}

.timeline {
  border-left: 2px solid #333;
  padding-left: 20px;
}

.timeline-item {
  margin-bottom: 20px;
}

.timeline-content {
  position: relative;
  margin-left: 20px;
}

.timeline-content h3 {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.timeline-content h4 {
  font-size: 1em;
  margin-bottom: 5px;
}

.timeline-content p {
  font-size: 0.9em;
  color: #555;
}

.prose {
  line-height: 1.6;
}

.full-width-top {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.bg-gray {
  background-color: #3C3C3C;
}

.p-4 {
  padding: 16px;
}

.rounded {
  border-radius: 8px;
}

.shadow {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.space-y-2 > * + * {
  margin-top: 8px;
}

.space-y-4 > * + * {
  margin-top: 16px;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #FFF;
}

.font-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.mb-4 {
  margin-bottom: 16px;
}

.mt-6 {
  margin-top: 24px;
}

.mb-2 {
  margin-bottom: 8px;
}

.hover\:text-blue-800:hover {
  color: #1D4ED8;
}
